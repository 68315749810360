import React from "react"
import { Link } from "gatsby"
import Tilt from 'react-vanilla-tilt'

import "../utils/css/components/project-card.css"


export default projectCard => (
    <Tilt className="Tilt project-card-tilt" options={{ scale: 2, max: 25 }} style={{ height: '100%', width: '100%' }} >
        <div className="Tilt-inner">
            <a href={projectCard.link} target="_blank"
                className="link-card"
                
            >
                <div className="bg-image"
                    style={
                        projectCard.thumbnail && {
                            backgroundImage: `url(${
                                projectCard.thumbnail.childImageSharp.fluid.src
                                })`
                        }
                    }
                ></div>
                <div className="card">
                    
                    <div className="categories">
                        {projectCard.categories.map( (category,i) => (
                            <span key={i}>{category.name}</span>
                        )
                        )}
                    </div>
                    <div className="project-name">{projectCard.title}</div>
                </div>
            </a>
        </div>
    </Tilt>
)
