import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Typed from 'react-typed'
import Img from 'gatsby-image'
import Tilt from 'react-vanilla-tilt'
import Fade from 'react-reveal/Fade'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectCard from "../components/projectCard"
import AreasSection from "../components/areas"
import ContactSection from "../components/contactCard"
import Hero from "../components/hero"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/components/glitch.css"
import "../utils/css/components/homepage.css"

import logo from "../../content/assets/logo.png"
import arrow from "../../content/assets/arrow-right.svg"
import arrowdown from "../../content/assets/arrow-down.svg"




function BlogIndex(props) {

    const data = props.data;
    const location = props.location;
    const siteTitle = data.site.siteMetadata.title
    const projects = data.allStrapiPortfoliolinks.edges
    const clients = data.allStrapiClientlogos.edges
    let current = 0

    const[currentNav,setCurrentNav]=React.useState(0); 


    const scrollPage = () => {

        const indexContainer = document.getElementById('index-container');
        const scrollButton = document.getElementById('scroll-button');
        scrollButton.classList.add("no-animation");
        const height = window.innerHeight;


        current = indexContainer.scrollTop / height; 

        if (currentNav < 5) {
            current = current + 1;

            if (current === 5) {
                scrollButton.classList.add("bottom");
            }
        } else {
            current = 0;
            scrollButton.classList.remove("bottom");
            scrollButton.classList.remove("no-animation");
        }

        console.log('current: ' + current);

        scrollTo(current);
    };

    const scrollTo = (section) => {
        const indexContainer = document.getElementById('index-container');
        const scrollButton = document.getElementById('scroll-button');
        const height = window.innerHeight;

        // current = section;

        setCurrentNav(section);

        
        if(section === 0){
            scrollButton.classList.remove("no-animation");
            scrollButton.classList.remove("bottom");
        }else if (section < 5){
            scrollButton.classList.add("no-animation");
            scrollButton.classList.remove("bottom");
        }else if(section === 5){
            scrollButton.classList.add("bottom");
        }

        indexContainer.scrollTop = height * section;
        console.log('current: ' + current + ', scroll' + (height * section));
    };

    return (
        <Layout title={siteTitle}>
            <SEO
                title="Studio Albatross - Multi-Disciplinary Creative Agency | Marketing | Films | Design"
                keywords={[`brand design`, `brand strategy`, `digital marketing`, `marketing consulting`, `marketing communication`, `social media marketing`, `visual design`, `interaction design`, `UI/UX design`, `User Experience`, `User Interface`, `corporate films`, `2D/3D animation`, `product videos`, `ad films`, `documentaries`, `best digital marketing agency`]}
                description="Studio Albatross is a multi-disciplinary creative agency that works with global innovative startups to significant conglomerates to tell their stories through our three wings - marketing, films & design."
            />
            <div id="index-container" className="scroll-container">
                <Hero></Hero>

                <div className="about-section section section-flex">
                    <Fade bottom >
                        <div className="section-title">The Wide Wings</div>
                        <p className="section-para">Studio Albatross is a multi-disciplinary creative agency. We are a group of storytellers and we fly with three wings - Marketing, Films & Design. Come join the beautiful flight with us!</p>
                        <Link to={`/about`} className="link-arrow">ABOUT US</Link>
                    </Fade>
                </div>
                <AreasSection
                    home={true}
                    other={false}
                    design={true}
                    film={true}
                    marketing={true}
                ></AreasSection>
                <div className="section section-flex rookery-section">
                    <div className="section-container">
                        <div className="row">
                            <Fade cascade>
                                <div className="col-5">
                                    <div className="section-title">The Rookery</div>
                                    <div className="section-para">
                                        <p>When you join the rookery of albatrosses you can’t help but fly long. </p>
                                        <p>From visionary startups to large corporations, we have worked with all… as long as the common belief is growth and independent voice.</p>
                                    </div>
                                </div>
                            </Fade>
                            <div className="col-7">
                                <Fade bottom cascade delay={200}>
                                    <div className="clients-grid">
                                        {clients.map((clients, i) => (
                                            <div className="clients-logo" key={i}>
                                                <Img fixed={clients.node.logo.childImageSharp.fixed} alt={`${clients.node.name}`} />
                                            </div>
                                        ))}
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-flex projects-section">
                    <Fade bottom cascade>
                        <div className="projects-grid-container">
                            <div className="projects-section-title">
                                <div className="section-title">The Flight</div>
                                <div className="section-para">
                                    The wide wings of albatross have covered a lot of water till now… and made it’s own little islands! Here are a few of our projects.
                        </div>
                            </div>
                            <div className="projects-card-1" key="project-1">
                                <ProjectCard key="project1"
                                    title={projects[0].node.title}
                                    thumbnail={projects[0].node.thumbnail}
                                    link={projects[0].node.link}
                                    categories={projects[0].node.categories}
                                />
                            </div>
                            <div className="projects-card-2">
                                <ProjectCard
                                    title={projects[1].node.title}
                                    thumbnail={projects[1].node.thumbnail}
                                    link={projects[1].node.link}
                                    categories={projects[1].node.categories}
                                />
                            </div>
                            <div className="projects-card-3">
                                <ProjectCard
                                    title={projects[2].node.title}
                                    thumbnail={projects[2].node.thumbnail}
                                    link={projects[2].node.link}
                                    categories={projects[2].node.categories}
                                />
                            </div>
                            <div className="projects-card-4">
                                <ProjectCard
                                    title={projects[3].node.title}
                                    thumbnail={projects[3].node.thumbnail}
                                    link={projects[3].node.link}
                                    categories={projects[3].node.categories}
                                />
                            </div>
                            <div className="projects-card-5">
                                <ProjectCard
                                    title={projects[4].node.title}
                                    thumbnail={projects[4].node.thumbnail}
                                    link={projects[4].node.link}
                                    categories={projects[4].node.categories}
                                />
                            </div>
                            <a href="http://studioalbatross.in/projects/" className="projects-card-more">
                                <Tilt options={{ max: 25, scale: 1, glare: true }} style={{ height: '100%', width: '100%' }}>
                                    <div className="more-projects">
                                        ALL PROJECTS
                                <img className="arrow" src={arrow} alt="Start" />

                                    </div>
                                </Tilt>
                            </a>
                        </div>
                    </Fade>
                </div>
                <ContactSection></ContactSection>
            </div>
            <div id="scroll-button" className="home-arrow arrow-down" onClick={scrollPage}>
                {/* <div className="hero-cta">Let's Go</div> */}
                <img src={arrowdown}  ></img>
            </div>
            <div className="home-navigation">
                <ul className={(currentNav===2) ? 'whitebg ' : ''}>
                    <li onClick={() => scrollTo(0)} className={(currentNav===0) ? 'selected' : ''}></li>
                    <li onClick={() => scrollTo(1)} className={(currentNav===1) ? 'selected' : ''}></li>
                    <li onClick={() => scrollTo(2)} className={(currentNav===2) ? 'selected ' : ''}></li>
                    <li onClick={() => scrollTo(3)} className={(currentNav===3) ? 'selected' : ''}></li>
                    <li onClick={() => scrollTo(4)} className={(currentNav===4) ? 'selected' : ''}></li>
                    <li onClick={() => scrollTo(5)} className={(currentNav===5) ? 'selected' : ''}></li>
                </ul>
            </div>
        </Layout>
    );
}





// --------------------------------------------

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allStrapiPortfoliolinks(filter: {featured: {eq: true}}) {
        edges {
          node {
            id
            link
            title
            enabled
            featured
            thumbnail {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            categories {
              name
            }
          }
        }
      }
      allStrapiClientlogos(filter: {featured: {eq: true}}) {
        edges {
          node {
            name
            logo {
                childImageSharp {
                  fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
          }
        }
      }
  }
`

export default props => (
    <StaticQuery
        query={indexQuery}
        render={data => (
            <BlogIndex location={props.location} props data={data} {...props} />
        )}
    />
)



