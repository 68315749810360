import React from "react";
import { Link } from "gatsby"


import banner_web from "../../content/assets/banner_web.jpg"
import video_web from "../../content/assets/banner-web.mp4"

import banner_mob from "../../content/assets/banner_mob.jpg"
import video_mob from "../../content/assets/banner-mob.mp4"

import logo from "../../content/assets/logo.png"
import arrowdown from "../../content/assets/arrow-down.svg"




class Hero extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.content = "aassdfsdf"
    }

    // scrollPage() {
    //     alert('scroll');
    //     // window.scrollTo(window.innerHeight);
    // }

    render() {
        const { location, children } = this.props;
        return (

            <section className="page-head section">
                <div className="my-video-container web-video">
                    <video className="  full" preload="auto" poster={banner_web} autoPlay loop muted playsInline>
                        <source src={video_web} type='video/mp4' />
                    </video>
                </div>
                <div className="my-video-container mobile-video">
                    <video className="  full" preload="auto" poster={banner_mob} autoPlay loop muted playsInline>
                        <source src={video_mob} type='video/mp4' />
                    </video>
                </div>
                <Link className="site-head-logo" to={`/`}>
                    <img src={logo} alt="Logo" />
                </Link>
                <div className="mid">&nbsp;</div>
               


            </section>


        );
    }
}

export default Hero;